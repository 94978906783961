import maxBy from 'lodash/maxBy';
import PropTypes from 'prop-types';
import React, {createContext, useState, useEffect} from 'react';
import Immutable from 'immutable';

import * as SocketIO from '../../socket-io';
import * as ExamsSocketApi from '../../api/exams-socket-api';

import {getClientMessagingNamespace} from 'shared-socket-io/socket-io-namespaces';

export const ChatContext = createContext({
    chatData: null,
    clearLocalChatHistory: null,
    messagingSocket: null,
    rooms: Immutable.List(),
    setChatData: null,
});

/**
 * Combines new messages with local history, ignoring any duplicates of messages we already have.
 *
 * @param {Immutable.List} currentMessageHistory
 * @param {Immutable.List} newMessages
 * @returns {Immutable.List}
 */
const mergeMessages = (currentMessageHistory, newMessages) => {
    if (currentMessageHistory.isEmpty()) {
        return newMessages;
    }

    let allMessages = currentMessageHistory;
    const messageIdsSet = Immutable.Set(allMessages.map((message) => message.get('messageId')));

    newMessages.forEach((message) => {
        if (!messageIdsSet.has(message.get('messageId'))) {
            allMessages = allMessages.push(message);
        }
    });

    return allMessages;
};

/**
 * @typedef {Immutable.Map} ChatData
 * @property {Immutable.Map<string, Immutable.List>} messagesByRoomId
 * @property {Immutable.Map<string, number>} unreadMessagesByRoomId
 * @property {string} expandedRoomId
 * @property {Immutable.Set<string>} activeRoomIds
 * @property {string} VISIBILITY_STATE
 */

/** @typedef {Immutable.Map<string, 'visible' | 'minimized' | 'closed' | Immutable.Map<string, any> | import('socket-io').Socket | Immutable.Set<string> | import('crypto').UUID>} ChatDataGenericTypes */
/** @typedef {React.Dispatch<React.SetStateAction<ChatDataGenericTypes>>} SetChatData */

const messageHandler = (userId, roomId, messages, setChatData) => {
    setChatData((prev) => {
        const currentMessageHistory = prev.getIn(['messagesByRoomId', roomId], Immutable.List());

        messages = Immutable.fromJS(messages);
        const allMessages = mergeMessages(currentMessageHistory, messages);

        const unreadMessages = prev.getIn(['unreadMessagesByRoomId', roomId], Immutable.List()).toJS();
        messages.forEach((messageEvent) => {
            if (!messageEvent.getIn(['message', 'usersRead'], Immutable.Map()).has(userId)) {
                unreadMessages.push(messageEvent);
            }
        });

        const messagesCreatedTs = messages.toJS().map((message) => message.createdTs);
        const lastReceivedMessageTs = maxBy(messagesCreatedTs, (createdTs) => new Date(createdTs).getTime());
        setLastMessageUpdate(userId, lastReceivedMessageTs);

        return prev
            .setIn(['activeRoomIds'], prev.get('activeRoomIds').add(roomId))
            .setIn(['messagesByRoomId', roomId], allMessages)
            .setIn(['unreadMessagesByRoomId', roomId], Immutable.fromJS(unreadMessages));
    });
};

const setPersistentStorage = (userId, chatData) => {
    localStorage.setItem(`chatData-${userId}`, chatData);
};

export const setLastMessageUpdate = (userId, lastMessageUpdateTs) => {
    localStorage.setItem(`lastChatDataUpdate-${userId}`, lastMessageUpdateTs);
};

export const clearMessagingStorage = (userId) => {
    if (!userId) {
        return;
    }
    localStorage.removeItem(`chatData-${userId}`);
    localStorage.removeItem(`lastChatDataUpdate-${userId}`);
};

export const clearMessaging = () => {
    for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.startsWith('chatData') || key.startsWith('lastChatDataUpdate') || key === 'multiRoomChatVisibility') {
            localStorage.removeItem(key);
        }
    }
};

export const getLastChatDataUpdate = (userId) => localStorage.getItem(`lastChatDataUpdate-${userId}`);

const loadPersistentStorage = (userId) => {
    /** @type {null | string} */
    const persistedState = localStorage.getItem(`chatData-${userId}`);
    const lastUpdate = getLastChatDataUpdate(userId);
    if (persistedState && lastUpdate) {
        /** @type {object} */
        const persistedStateObject = JSON.parse(persistedState);
        persistedStateObject.activeRoomIds = Immutable.OrderedSet(persistedStateObject.activeRoomIds);
        return [Immutable.fromJS(persistedStateObject), new Date(lastUpdate)];
    }
    return [];
};

const MessagingContext = (props) => {
    const {
        children,
        clientId,
        examInterfaceToken,
        isMultiRoom = false,
        loggedInUser,
        room,
        token,
        doMinimize = false,
    } = props;

    if (!token && !examInterfaceToken) {
        throw new Error('Either a token or examInterfaceToken is required');
    }

    const [chatData, setChatData] = useState(null);
    const [messagingReady, setMessagingReady] = useState(false);
    const [messagingSocket, setMessagingSocket] = useState(null);
    const [rooms, setRooms] = useState(isMultiRoom ? Immutable.List() : Immutable.List([room]));

    const clearLocalChatHistory = () => {
        clearMessaging();
        setChatData((prev) => {
            return prev.set('messagesByRoomId', Immutable.Map()).set('unreadMessagesByRoomId', Immutable.Map());
        });
    };

    const chatContext = {
        chatData,
        clearLocalChatHistory,
        messagingSocket,
        rooms,
        setChatData: setChatData,
    };

    useEffect(() => {
        if (messagingReady) {
            setPersistentStorage(loggedInUser.get('userId'), JSON.stringify(chatData.toJS()));
        }
    }, [chatData, messagingReady]);

    useEffect(() => {
        const setupMessaging = async () => {
            const [localState, lastUpdate] = loadPersistentStorage(loggedInUser.get('userId'));
            const initialChatData =
                localState && lastUpdate
                    ? localState
                    : Immutable.fromJS({
                          activeRoomIds: Immutable.OrderedSet(),
                          expandedRoomId: null,
                          loggedInUser,
                          messagesByRoomId: Immutable.Map(),
                          roomsByRoomId: Immutable.Map(),
                          textFieldIsFocused: null,
                          unreadMessagesByRoomId: Immutable.Map(),
                      });
            setChatData(initialChatData);
            const ms = SocketIO.connectSocketIO(
                getClientMessagingNamespace(clientId),
                examInterfaceToken ? {examInterfaceToken} : {token},
            );

            const receiveMessagesCallback = (roomId, messages) =>
                messageHandler(loggedInUser.get('userId'), roomId, messages, setChatData);
            ExamsSocketApi.receiveMessages(ms, receiveMessagesCallback);
            setMessagingSocket(ms);

            if (isMultiRoom) {
                try {
                    const connectedRooms = Immutable.fromJS((await ExamsSocketApi.connectMonitorChat(ms)).data);
                    const roomsByRoomId = Immutable.Map(connectedRooms.map((r) => [r.get('roomId'), r]));
                    setChatData((prev) => {
                        return prev
                            .set('roomsByRoomId', roomsByRoomId)
                            .set(
                                'activeRoomIds',
                                Immutable.OrderedSet(
                                    prev.get('activeRoomIds').filter((roomId) => roomsByRoomId.has(roomId)),
                                ),
                            )
                            .set(
                                'expandedRoomId',
                                roomsByRoomId.has(prev.get('expandedRoomId'))
                                    ? prev.get('expandedRoomId')
                                    : roomsByRoomId.first()?.get('roomId'),
                            );
                    });
                    setRooms(connectedRooms);

                    if (lastUpdate) {
                        await Promise.all(
                            connectedRooms.map((r) => {
                                // TODO SCLD-15933 bulk enter rooms
                                return ExamsSocketApi.monitorOpenRoomChat(
                                    ms,
                                    r.get('roomId'),
                                    lastUpdate?.toISOString(),
                                );
                            }),
                        );
                    }
                } catch (err) {
                    console.error(err);
                }
            } else {
                setRooms(Immutable.List([room]));
                await ExamsSocketApi.connectSpChat(ms, room.get('roomId'), lastUpdate?.toISOString());
            }

            setMessagingReady(true);
        };

        setupMessaging();

        return () => {
            messagingSocket?.removeAllListeners();
            messagingSocket?.close();
        };
    }, []);

    useEffect(() => {
        if (doMinimize) {
            setChatData((prev) => prev.set('expandedRoomId', null));
        }
    }, [doMinimize]);

    return (
        <div style={{zIndex: 999}}>
            {messagingReady && <ChatContext.Provider value={chatContext}>{children}</ChatContext.Provider>}
        </div>
    );
};

MessagingContext.propTypes = {
    children: PropTypes.node.isRequired,
    loggedInUser: PropTypes.object.isRequired,
    clientId: PropTypes.string.isRequired,
    examInterfaceToken: PropTypes.string,
    doMinimize: PropTypes.bool,
    isMultiRoom: PropTypes.bool,
    // @ts-expect-error TS2345: Argument of type typeof Map is not assignable to parameter of type new (...args: any[]) => any
    room: PropTypes.instanceOf(Immutable.Map),
    token: PropTypes.string,
};

export default MessagingContext;
