import type {
    TypographyOptions as MuiTypographyOptions,
    TypographyStyleOptions,
} from '@material-ui/core/styles/createTypography';
import type {PaletteOptions} from '@material-ui/core/styles/createPalette';

//#region Font Weight

const FONT_WEIGHT_REGULAR = 400;
const FONT_WEIGHT_BOLD = 700;

//#endregion

//#region Headline

const HEADLINE_FONT_SIZE_XL = 48;
const HEADLINE_LINE_HEIGHT_XL = '6.4rem';

const HEADLINE_FONT_SIZE_L = 40;
const HEADLINE_LINE_HEIGHT_L = '5.2rem';

const HEADLINE_FONT_SIZE_M = 32;
const HEADLINE_LINE_HEIGHT_M = '4.0rem';

const HEADLINE_FONT_SIZE_S = 28;
const HEADLINE_LINE_HEIGHT_S = '3.6rem';

const HEADLINE_FONT_SIZE_XS = 24;
const HEADLINE_LINE_HEIGHT_XS = '3.2rem';

const HEADLINE_FONT_SIZE_XXS = 20;
const HEADLINE_LINE_HEIGHT_XXS = '2.4rem';

const HEADLINE_FONT_WEIGHT = FONT_WEIGHT_BOLD;

//#endregion Headline

//#region Paragraphs

const PARAGRAPHS_FONT_SIZE_XL = 24;
const PARAGRAPHS_LINE_HEIGHT_XL = '3.6rem';

const PARAGRAPHS_FONT_SIZE_L = 20;
const PARAGRAPHS_LINE_HEIGHT_L = '3.2rem';

const PARAGRAPHS_FONT_SIZE_M = 18;
const PARAGRAPHS_LINE_HEIGHT_M = '2.8rem';

const PARAGRAPHS_FONT_SIZE_S = 16;
const PARAGRAPHS_LINE_HEIGHT_S = '2.4rem';

const PARAGRAPHS_FONT_SIZE_XS = 14;
const PARAGRAPHS_LINE_HEIGHT_XS = '2.0rem';

//#endregion Paragraphs

//#region Components

const COMPONENTS_FONT_SIZE_XL = 24;
const COMPONENTS_LINE_HEIGHT_XL = '2.8rem';

const COMPONENTS_FONT_SIZE_L = 20;
const COMPONENTS_LINE_HEIGHT_L = '2.4rem';

const COMPONENTS_FONT_SIZE_M = 18;
const COMPONENTS_LINE_HEIGHT_M = '2.4rem';

const COMPONENTS_FONT_SIZE_S = 16;
const COMPONENTS_LINE_HEIGHT_S = '2.0rem';

const COMPONENTS_FONT_SIZE_XS = 14;
const COMPONENTS_LINE_HEIGHT_XS = '1.6rem';

const COMPONENTS_FONT_SIZE_XXS = 12;
const COMPONENTS_LINE_HEIGHT_XXS = '1.6rem';

// Used for uppercase variant only
const COMPONENTS_LETTER_SPACING = '0.05rem';

//#endregion Components

declare module '@material-ui/core/styles/createTypography' {
    export interface Typography {
        headlineXl: TypographyStyle;
        headlineL: TypographyStyle;
        headlineM: TypographyStyle;
        headlineS: TypographyStyle;
        headlineXs: TypographyStyle;
        headlineXxs: TypographyStyle;
        paragraphXl: TypographyStyle;
        paragraphL: TypographyStyle;
        paragraphM: TypographyStyle;
        paragraphS: TypographyStyle;
        paragraphXs: TypographyStyle;
        componentXl: TypographyStyle;
        componentL: TypographyStyle;
        componentM: TypographyStyle;
        componentS: TypographyStyle;
        componentXs: TypographyStyle;
        componentXxs: TypographyStyle;
        componentUppercaseM: TypographyStyle;
        componentUppercaseS: TypographyStyle;
        componentUppercaseXs: TypographyStyle;
        fontWeightRegular: number;
        fontWeightBold: number;
    }

    export interface TypographyOptions {
        headlineXl: TypographyStyleOptions;
        headlineL: TypographyStyleOptions;
        headlineM: TypographyStyleOptions;
        headlineS: TypographyStyleOptions;
        headlineXs: TypographyStyleOptions;
        headlineXxs: TypographyStyleOptions;
        paragraphXl: TypographyStyleOptions;
        paragraphL: TypographyStyleOptions;
        paragraphM: TypographyStyleOptions;
        paragraphS: TypographyStyleOptions;
        paragraphXs: TypographyStyleOptions;
        componentXl: TypographyStyleOptions;
        componentL: TypographyStyleOptions;
        componentM: TypographyStyleOptions;
        componentS: TypographyStyleOptions;
        componentXs: TypographyStyleOptions;
        componentXxs: TypographyStyleOptions;
        componentUppercaseM: TypographyStyleOptions;
        componentUppercaseS: TypographyStyleOptions;
        componentUppercaseXs: TypographyStyleOptions;
    }
}

const commonTypographyStyleOptions: Partial<TypographyStyleOptions> = {
    fontFeatureSettings: `'liga' off`,
    fontStyle: 'normal',
    textRendering: 'optimizeLegibility',
};

/*
 * Font sizes can only be defined as numeric pixels for some reason
 */
const getTypography = (paletteOptions: PaletteOptions): MuiTypographyOptions => ({
    fontFamily: ['Lato', 'sans-serif'].join(','),
    fontSize: COMPONENTS_FONT_SIZE_S,
    fontWeightRegular: FONT_WEIGHT_REGULAR,
    fontWeightBold: FONT_WEIGHT_BOLD,
    // all the built-in MUI variants (note that these override some of the base attributes above, so we need to
    // re-specify them instead of relying on inheritance)
    body1: {
        ...commonTypographyStyleOptions,
        color: paletteOptions.text.primary,
        fontSize: COMPONENTS_FONT_SIZE_S,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
    },
    body2: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_XS,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_XS,
    },
    button: {
        ...commonTypographyStyleOptions,
        textTransform: 'none',
        fontSize: COMPONENTS_FONT_SIZE_S,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
    },
    caption: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_S,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
    },
    h1: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XL,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_XL,
    },
    h2: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_L,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_L,
    },
    h3: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_M,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_M,
    },
    h4: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_S,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_S,
    },
    h5: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XS,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_XS,
    },
    h6: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XXS,
        fontWeight: HEADLINE_FONT_WEIGHT,
        lineHeight: HEADLINE_LINE_HEIGHT_XXS,
    },
    overline: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_M,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_M,
    },
    subtitle1: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_M,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_M,
    },
    subtitle2: {
        ...commonTypographyStyleOptions,
        color: paletteOptions.text.disabled,
        fontSize: COMPONENTS_FONT_SIZE_S,
        fontWeight: FONT_WEIGHT_REGULAR,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
    },
    // custom Life variants
    //#region Headline
    headlineXl: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XL,
        lineHeight: HEADLINE_LINE_HEIGHT_XL,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    headlineL: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_L,
        lineHeight: HEADLINE_LINE_HEIGHT_L,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    headlineM: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_M,
        lineHeight: HEADLINE_LINE_HEIGHT_M,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    headlineS: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_S,
        lineHeight: HEADLINE_LINE_HEIGHT_S,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    headlineXs: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XS,
        lineHeight: HEADLINE_LINE_HEIGHT_XS,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    headlineXxs: {
        ...commonTypographyStyleOptions,
        fontSize: HEADLINE_FONT_SIZE_XXS,
        lineHeight: HEADLINE_LINE_HEIGHT_XXS,
        fontWeight: FONT_WEIGHT_BOLD,
    },
    //#endregion Headline
    //#region Paragraph
    paragraphXl: {
        ...commonTypographyStyleOptions,
        fontSize: PARAGRAPHS_FONT_SIZE_XL,
        lineHeight: PARAGRAPHS_LINE_HEIGHT_XL,
    },
    paragraphL: {
        ...commonTypographyStyleOptions,
        fontSize: PARAGRAPHS_FONT_SIZE_L,
        lineHeight: PARAGRAPHS_LINE_HEIGHT_L,
    },
    paragraphM: {
        ...commonTypographyStyleOptions,
        fontSize: PARAGRAPHS_FONT_SIZE_M,
        lineHeight: PARAGRAPHS_LINE_HEIGHT_M,
    },
    paragraphS: {
        ...commonTypographyStyleOptions,
        fontSize: PARAGRAPHS_FONT_SIZE_S,
        lineHeight: PARAGRAPHS_LINE_HEIGHT_S,
    },
    paragraphXs: {
        ...commonTypographyStyleOptions,
        fontSize: PARAGRAPHS_FONT_SIZE_XS,
        lineHeight: PARAGRAPHS_LINE_HEIGHT_XS,
    },
    //#endregion Paragraph
    //#region Component
    componentXl: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_XL,
        lineHeight: COMPONENTS_LINE_HEIGHT_XL,
    },
    componentL: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_L,
        lineHeight: COMPONENTS_LINE_HEIGHT_L,
    },
    componentM: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_M,
        lineHeight: COMPONENTS_LINE_HEIGHT_M,
    },
    componentS: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_S,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
    },
    componentXs: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_XS,
        lineHeight: COMPONENTS_LINE_HEIGHT_XS,
    },
    componentXxs: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_XXS,
        lineHeight: COMPONENTS_LINE_HEIGHT_XXS,
    },
    // Component Uppercase
    componentUppercaseM: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_M,
        lineHeight: COMPONENTS_LINE_HEIGHT_M,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: COMPONENTS_LETTER_SPACING,
        textTransform: 'uppercase',
    },
    componentUppercaseS: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_S,
        lineHeight: COMPONENTS_LINE_HEIGHT_S,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: COMPONENTS_LETTER_SPACING,
        textTransform: 'uppercase',
    },
    componentUppercaseXs: {
        ...commonTypographyStyleOptions,
        fontSize: COMPONENTS_FONT_SIZE_XS,
        lineHeight: COMPONENTS_LINE_HEIGHT_XS,
        fontWeight: FONT_WEIGHT_BOLD,
        letterSpacing: COMPONENTS_LETTER_SPACING,
        textTransform: 'uppercase',
    },
    //#endregion Component
});

export default getTypography;
